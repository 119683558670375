import React, { useEffect, useState } from "react";

import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  Toolbar,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import LicensesTable from "../features/licenses/LicensesTable";
import CustomerProfile from "../features/customer/CustomerProfile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LicensesPage() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState(null);

  useEffect(() => {
    if (activeCustomer) {
      setDialogOpen(true);
    } else {
      return;
    }
  }, [activeCustomer]);

  const handleTableClick = (customerId) => {
    setActiveCustomer(customerId);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setActiveCustomer(null);
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar position="relative" style={{ backgroundColor: "#2c387e" }}>
          <Toolbar>
            <IconButton onClick={handleDialogClose} style={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <CustomerProfile customerId={activeCustomer} />
      </Dialog>
      <Container maxWidth="xl">
        <LicensesTable hasHeader={false} handleCellClick={handleTableClick} />
      </Container>
    </div>
  );
}

export default LicensesPage;
