import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";

import { fetchCustomers, selectAllCustomers } from "../customer/customerSlice";
import { fetchPlugins, selectAllPlugins } from "../plugins/pluginsSlice";
import { addNewLicense, fetchLicenses } from "./licensesSlice";
import { getLatestPlugins } from "../../utils/versionUtil";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
    marginLeft: -5,
  },
}));

const initialLicense = {
  customerId: null,
  expiry: null,
  type: null,
  plugins: [],
  comment: "",
};

const AddLicenseDialog = ({ customer, editPlugins }) => {
  const classes = useStyles();
  const dt = new Date();
  dt.setMonth(dt.getMonth() + 1);
  const defaultDate = dateFormatter(dt);
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [license, setLicense] = useState(initialLicense);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const allCustomers = useSelector(selectAllCustomers);
  const plugins = useSelector(selectAllPlugins);
  const latestPlugins = getLatestPlugins(plugins);
  const customersStatus = useSelector((state) => state.customers.fetchStatus);
  const pluginsStatus = useSelector((state) => state.plugins.fetchStatus);

  useEffect(() => {
    if (customersStatus === "idle") {
      dispatch(fetchCustomers());
    }
    if (pluginsStatus === "idle") {
      dispatch(fetchPlugins());
    }
  }, [customersStatus, pluginsStatus, dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
    if (customer) {
      setLicense({ ...license, customerId: customer.id, expiry: selectedDate });
    }
  };
  const handleClose = () => {
    setOpen(false);
    setLicense(initialLicense);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setLicense({ ...license, expiry: date });
  };

  function dateFormatter(date) {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let day;
    let month;
    if (d < 10) {
      day = `0${d}`;
    } else {
      day = d;
    }
    if (m < 10) {
      month = `0${m}`;
    } else {
      month = m;
    }
    let y = date.getFullYear();
    return `${y}-${month}-${day}`;
  }

  const handleAdd = async (event) => {
    //licenses get added as an Array of Objects
    let licenseArr = [];
    license.plugins.forEach((plugin) => {
      let newLicense = {
        customerId: license.customerId,
        expiry: license.expiry,
        pluginkey: plugin.pluginkey,
        version: plugin.version,
        zipfile: plugin.zipfile,
        type: license.type,
        comment: license.comment,
      };
      licenseArr.push(newLicense);
    });
    await dispatch(addNewLicense(licenseArr)).then((res) => {
      if (res.type === "licenses/addNewLicense/fulfilled") {
        dispatch(fetchLicenses());
        handleClose();
      }
    });
  };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setLicense({ ...license, [name]: value });
    };

  const cantAdd = Boolean(
    license.plugins.length && license.type && license.expiry
  );

  return (
    <div>
      <Button
        variant="contained"
        aria-label="add license"
        onClick={handleClickOpen}
      >
        Add License
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-license-form"
        fullWidth={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "5px",
          }}
        >
          <DialogTitle id="add-license-form">Add License</DialogTitle>
        </div>
        <DialogContent className="license-dialog-content">
          {customer ? null : (
            <FormControl>
              <InputLabel>Customer</InputLabel>
              <Select
                margin="dense"
                label="Customer"
                type="select"
                fullWidth
                onChange={handleChange("customerId")}
              >
                {allCustomers.map((customer) => (
                  <MenuItem value={customer.id} key={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            {editPlugins ? (
              <>
                {editPlugins.map((plugin, i) => (
                  <Chip key={i} label={plugin.pluginkey} />
                ))}
              </>
            ) : (
              <Autocomplete
                id="add-license-plugin"
                options={latestPlugins}
                multiple={true}
                getOptionLabel={(option) => option.pluginkey}
                renderInput={(params) => (
                  <TextField {...params} label="Plugin" variant="standard" />
                )}
                onChange={(e, value) =>
                  setLicense({ ...license, plugins: value })
                }
              />
            )}
          </FormControl>
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              defaultValue={""}
              margin="dense"
              label="Type"
              type="select"
              fullWidth
              onChange={handleChange("type")}
            >
              <MenuItem value="demo">Demo</MenuItem>
              <MenuItem value="eval">Eval</MenuItem>
              <MenuItem value="perm">Perm</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <TextField
              id="comment"
              label="Comment"
              type="text"
              multiline
              rows={4}
              fullWidth
              className={classes.textField}
              onChange={handleChange("comment")}
            />
          </FormControl>
          <form className={classes.container}>
            <TextField
              id="date"
              label="Expiry Date"
              type="date"
              defaultValue={selectedDate}
              fullWidth
              onChange={(e) => {
                handleDateChange(e.target.value);
              }}
            />
          </form>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          <Button
            color="primary"
            disabled={!cantAdd}
            variant="contained"
            onClick={handleAdd}
          >
            Add
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddLicenseDialog;
