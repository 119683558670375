import React from 'react'

import ProtoTable from '../app/components/ProtoTable'

// const compA = {
//     A: 'dataA',
//     B: 'dataB',
//     C: 'dataC',
//     X: 'dataX',
//     Y: 'dataY',
//     Z: 'dataZ'
// };
// const compB = {
//     A: 'dataA',
//     B: 'dataB',
//     D: 'dataD',
//     Q: 'dataQ',
//     Y: 'dataY',
//     Z: 'dataZ'
// };

// function intersectObjects(o1, o2){
//     return Object.keys(o1).filter(item => item in o2)
// }

// function differenceObjects(o1, o2){
//     return Object.keys(o1).filter(i => !intersectObjects(o1, o2).includes(i))
// }

// //OBJECTS
// console.log('Shared properties: ', intersectObjects(compA, compB))
// console.log('Not found, CompA: ', differenceObjects(compA, compB))
// console.log('Not found, CompB: ', differenceObjects(compB, compA))


// const test = { 
//     "account_id" : "Faciligate" , 
//     "entity" : "ci" , 
//     "page" : 0 , 
//     "size" : 39 , 
//     "results" : [ 
//         { 
//             "account_id" : "Faciligate" , 
//             "edge_device_id" : "SEDGE_C7" , 
//             "amazon_aws_conn_info_id" :  null  , 
//             "ciname" : "gateway" , 
//             "cisco_ucs_conn_info_id" :  null  , 
//             "dns" : "gateway" , 
//             "fs_ba_eventdefid" :  null  , 
//             "fs_domain" :  null  , 
//             "fs_proxy_ciname" :  null  , 
//             "infoblox_conn_info_id" :  null  , 
//             "ip" : "192.168.0.1" , 
//             "ldap_conn_info_id" :  null  , 
//             "netapp_credential_id" :  null  , 
//             "netapp_dfm_conn_info_id" :  null  , 
//             "port" : 8042 , 
//             "snmp_credential_id" :  null  , 
//             "smis_conn_info_id" :  null  , 
//             "ssh_credential_id" :  null  , 
//             "status" : 0 , "useip" : 1 , 
//             "vm_conn_info_id" :  null  , 
//             "vm_name" :  null  , 
//             "wmi_credential_id" :  null  , 
//             "ci_profile" : { 
//                 "architecture" :  null  , 
//                 "asset_type" :  null  , 
//                 "contact" :  null  , 
//                 "devicetype" : "Generic" , 
//                 "fs_port_alias_id" :  null  , 
//                 "hardware" :  null  , 
//                 "location" :  null  , 
//                 "manufacturer" :  null  , 
//                 "macaddress" : "7c:5a:1c:61:9d:7e" , 
//                 "model" :  null  , 
//                 "name" : "192.168.0.1" , 
//                 "notes" :  null  , 
//                 "os" : "AVtech Room Alert 26W environmental monitor" , 
//                 "os_distribution" : "AVtech Room Alert 26W environmental monitor" , 
//                 "primary_stack_layer" : 4 , 
//                 "secondary_stack_layer" : 0 , 
//                 "serialno" :  null  , 
//                 "software" :  null  , 
//                 "tag" :  null  , 
//                 "total_ram" :  null  , 
//                 "url" :  null  , 
//                 "version" :  null  , 
//                 "custom_fields" : [ ] , 
//                 "imageid" :  null 
//             } , 
//             "ci_storage" : [ ] , 
//             "ci_memory" : [ ] , 
//             "ci_interface" : [ ] , 
//             "ci_cpu" : [ ] , 
//             "ci_bp" : [ ] , 
//             "logicalgroup" : [ ] , 
//             "servicegroup" : [ ] , 
//             "ci_snmp_table" : [ ] , 
//             "ci_vm_discovery" : { 
//                 "ci_vm_virtual_datacenter" : [ ] , 
//                 "ci_vm_virtual_nic" : [ ] , 
//                 "ci_vm_datastore" : [ ] , 
//                 "ci_vm_vmnic" : [ ]
//             } , 
//             "ci_netapp" : { 
//                 "ci_netapp_filer" : { 
//                     "ip" :  null  , 
//                     "ci_netapp_aggregate" : [ ] , 
//                     "ci_netapp_volume" : [ ] , 
//                     "attributeset_id" :  null 
//                 }
//             }
//         }
//     ]
// }
// const test2 = { 
//     "account_id" : "Faciligate" , 
//     "edge_device_id" : "SEDGE_C7" , 
//     "amazon_aws_conn_info_id" :  null  , 
//     "ciname" : "192.168.0.11" , 
//     "cisco_ucs_conn_info_id" :  null  , 
//     "dns" :  null  , 
//     "fs_ba_eventdefid" :  null  , 
//     "fs_domain" :  null  , 
//     "fs_proxy_ciname" :  null  , 
//     "infoblox_conn_info_id" :  null  , 
//     "ip" : "192.168.0.11" , 
//     "ldap_conn_info_id" :  null  , 
//     "netapp_credential_id" :  null  , 
//     "netapp_dfm_conn_info_id" :  null  , 
//     "port" : 8042 , 
//     "snmp_credential_id" :  null  , 
//     "smis_conn_info_id" :  null  , 
//     "ssh_credential_id" :  null  , 
//     "status" : 0 , 
//     "useip" : 1 , 
//     "vm_conn_info_id" :  null  , 
//     "vm_name" :  null  , 
//     "wmi_credential_id" :  null  , 
//     "ci_profile" : { 
//         "architecture" :  null  , 
//         "asset_type" : "Server" , 
//         "contact" :  null  , 
//         "devicetype" : "Server" , 
//         "fs_port_alias_id" :  null  , 
//         "hardware" :  null  , 
//         "location" :  null  , 
//         "manufacturer" :  null  , 
//         "macaddress" : "ec:3d:fd:c1:78:e7" , 
//         "model" :  null  , 
//         "name" : "192.168.0.11" , 
//         "notes" :  null  , 
//         "os" : "Linux 2.6.32 - 3.9" , 
//         "os_distribution" : "Linux 2.6.32 - 3.9" , 
//         "primary_stack_layer" : 4 , 
//         "secondary_stack_layer" : 0 , 
//         "serialno" :  null  , 
//         "software" :  null  , 
//         "tag" :  null  , 
//         "total_ram" :  null  , 
//         "url" :  null  , 
//         "version" :  null  , 
//         "custom_fields" : [ ] , 
//         "imageid" :  null 
//     } , 
//     "ci_storage" : [ ] , 
//     "ci_memory" : [ ] , 
//     "ci_interface" : [ ] , 
//     "ci_cpu" : [ ] , 
//     "ci_bp" : [ ] , 
//     "logicalgroup" : [ ] , 
//     "servicegroup" : [ ] , 
//     "ci_snmp_table" : [ ] , 
//     "ci_vm_discovery" : { 
//         "ci_vm_virtual_datacenter" : [ ] , 
//         "ci_vm_virtual_nic" : [ ] , 
//         "ci_vm_datastore" : [ ] , 
//         "ci_vm_vmnic" : [ ]
//     } , 
//     "ci_netapp" : { 
//         "ci_netapp_filer" : { 
//             "ip" :  null  , 
//             "ci_netapp_aggregate" : [ ] , 
//             "ci_netapp_volume" : [ ] , 
//             "attributeset_id" :  null 
//         }
//     }
// }

// function expandResults(obj){
//     if('results' in obj){
//         let newObj = { ...obj.results[0] }
//         for(var p in obj) newObj[p] = obj[p];
//         delete newObj['results'];
//         return newObj
//     }
//     return obj
// }

// function haveSameData (obj1, obj2) { 
//     return Object.keys(obj1).every( 
//             key => obj2.hasOwnProperty(key) 
//                 && obj2[key] === obj1[key]); 
// } 


//console.log(expandResults(test))
//console.log(haveSameData(expandResults(test), expandResults(test2)))
// console.log(intersectObjects(expandResults(test), expandResults(test2)));
// console.log(differenceObjects(expandResults(test), expandResults(test2)));
// console.log(intersectObjects(customersA[0], customersB[0]));
// console.log(differenceObjects(customersA[0], customersA[0], customersB[0]));
// console.log(differenceObjects(customersB[0], customersA[0], customersB[0]));

const TestPage = () => {
    return (
        <div>
            <ProtoTable />

        </div>
    )
}

export default TestPage
