import React from 'react'

import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import FormSpy from '@data-driven-forms/react-form-renderer/dist/cjs/form-spy';

import Button from '@material-ui/core/Button';

const CustomForm = ({ formFields, schema, type }) => {
    const { handleSubmit, onReset, onCancel, getState } = useFormApi();
    const { submitting, valid, pristine } = getState();
    return (
      <form onSubmit={handleSubmit}>
        {formFields}
        <FormSpy>
          {() => (
            <div style={{ marginTop: 8 }}>
              <Button 
                color="primary"
                disabled={submitting || !valid} 
                style={{ marginRight: 8 }} 
                type="submit" 
                variant="contained"
            >
                {type === 'add' ? 'Add' : 'Save'}
              </Button>
              {type === 'add' ? 
              <Button 
                color="primary"
                disabled={pristine} 
                style={{ marginRight: 8 }} 
                variant="contained"
                onClick={onReset} 
              >
                Reset
              </Button> : null}
              <Button 
                color="secondary" 
                variant="contained"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          )}
        </FormSpy>
      </form>
    );
};

export default CustomForm
