import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER } from "../../app/constants";

const auditsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

const initialState = auditsAdapter.getInitialState({
  fetchStatus: "idle",
  fetchError: null,
});

export const fetchAudits = createAsyncThunk(
  "audits/fetchAudits",
  async (customerId) => {
    const response = await axios.get(`${SERVER}/rest/audit?id=${customerId}`);
    return response.data;
  }
);

const auditsSlice = createSlice({
  name: "audits",
  initialState,
  extraReducers: {
    [fetchAudits.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchAudits.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      auditsAdapter.setAll(state, action.payload);
    },
    [fetchAudits.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.fetchError = action.error.message;
    },
  },
});

export default auditsSlice.reducer;

export const {
  selectAll: selectAllAudits,
  selectById: selectAuditById,
  selectIds: selectAuditIds,
} = auditsAdapter.getSelectors((state) => state.audits);
