import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";

import { fetchAudits, selectAllAudits } from "./auditsSlice";

import EnhancedTable from "../../app/components/EnhancedTable";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <FormControl>
      <Select
        defaultValue={""}
        value={filterValue ? filterValue : ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <MenuItem value="">All</MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function timeParser(val) {
  let parsed = val.split("T");
  return `${parsed[0]} at ${parsed[1].substring(0, 5)}`;
}

function AuditsTable({ customerId }) {
  const dispatch = useDispatch();
  const auditsStatus = useSelector((state) => state.audits.fetchStatus);
  const audits = useSelector(selectAllAudits);

  useEffect(() => {
    dispatch(fetchAudits(customerId));
  }, [customerId, dispatch]);

  const COLUMNS = [
    {
      Header: "Timestamp",
      accessor: "timestamp",
      Cell: (cell) => {
        return <span>{timeParser(cell.value)}</span>;
      },
    },
    {
      Header: "User",
      accessor: "user",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Object Name",
      accessor: "objectname",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Object Type",
      accessor: "objecttype",
      Filter: SelectColumnFilter,
      filter: "includes",
      Cell: (row) => (
        <Tooltip
          title={
            <>
              <b>{"Before: "}</b>
              {row.row.original.beforevalue}
              <br></br>
              <b>{"After: "}</b>
              {row.row.original.after}
            </>
          }
        >
          <span>{row.value}</span>
        </Tooltip>
      ),
    },
    {
      Header: "Operation",
      accessor: "operation",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Expiry",
      accessor: "expiry",
    },
  ];

  const auditColumns = useMemo(() => COLUMNS, [COLUMNS]);
  const auditData = useMemo(() => [...audits], [audits]);

  let auditTable;
  if (auditsStatus === "loading") {
    auditTable = <CircularProgress />;
  } else if (auditsStatus === "succeeded") {
    auditTable = (
      <EnhancedTable
        sortPref={{ id: "timestamp", desc: true }}
        columns={auditColumns}
        data={auditData}
      />
    );
  } else if (auditsStatus === "failed") {
    auditTable = <p>Failed to load content</p>;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {auditTable}
    </div>
  );
}

export default AuditsTable;
