import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectAllLicenses,
  fetchLicenses,
  addNewLicense,
  downloadLicenses,
} from "./licensesSlice";
import { fetchCustomers, selectAllCustomers } from "../customer/customerSlice";

import {
  Checkbox,
  CircularProgress,
  IconButton,
  Button,
  Select,
  MenuItem,
  Toolbar,
  FormControl,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../app/components/TablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import {
  useTable,
  useFilters,
  useSortBy,
  useRowSelect,
  usePagination,
} from "react-table";

import AddLicenseDialog from "./AddLicenseDialog";
import EditLicensesDialog from "./EditLicensesDialog";
import ConfirmDialog from "../../app/components/ConfirmDialog";

import { matchSorter } from "match-sorter";
import { GetApp, Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { dateDiff, parseDate } from "../../utils/formatUtil";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    padding: "1px",
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} color="primary" />
      </>
    );
  }
);

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <TextField
      className="table-input"
      variant="outlined"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <div style={{ display: "flex" }}>
      <TextField
        style={{ width: 70, marginRight: "5px" }}
        type="number"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <IconButton
        aria-label="clear filter"
        size="small"
        disableFocusRipple
        disableRipple
        onClick={() => setFilter(undefined)}
      >
        <Clear
          fontSize="inherit"
          color={filterValue === undefined ? "disabled" : "primary"}
        />
      </IconButton>
    </div>
  );
}

function filterLessThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = dateDiff(new Date(), parseDate(row.values[id]));
    return Math.abs(rowValue) <= filterValue;
  });
}

//remove the filter if it's not a number
filterLessThan.autoRemove = (val) => typeof val !== "number";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <FormControl>
      <Select
        defaultValue={""}
        value={filterValue ? filterValue : ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <MenuItem value="">All</MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option ? option : ""}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({
  columns,
  data,
  skipPageReset,
  updateMyData,
  customer,
  hasHeader,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const handleDeleteLicenses = async (selectedLicenses) => {
    selectedLicenses.forEach((license) => {
      license.deleted = true;
    });
    await dispatch(addNewLicense(selectedLicenses))
      .then((res) => {
        if (res.type === "licenses/addNewLicense/fulfilled") {
          setConfirmOpen(false);
          dispatch(fetchLicenses());
        }
      })
      .catch((err) => console.log(err));
  };

  const returnSelectedLicensesNames = (selectedLicenses) => {
    let text = "";
    selectedLicenses.forEach((license, i) => {
      text += license.pluginkey;
      if (i !== selectedLicenses.length - 1) text += ", ";
    });

    return text;
  };

  const handleLicensesDownload = () => {
    dispatch(downloadLicenses(customer.id));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      initialState: { pageSize: 50 },
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (customer) {
        hooks.allColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const selectedLicenses = selectedFlatRows.map((d) => d.original);

  return (
    <TableContainer>
      {hasHeader ? (
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div>
            <Button
              style={{ marginRight: 5 }}
              startIcon={<GetApp />}
              variant="contained"
              aria-label="download license keys"
              onClick={handleLicensesDownload}
            >
              Download License Keys
            </Button>
          </div>
          <div style={{ display: "flex" }}>
            <AddLicenseDialog customer={customer} />
            <EditLicensesDialog
              disabled={selectedLicenses.length === 0}
              customer={customer}
              selectedLicenses={selectedLicenses}
            />
            <Button
              style={{ marginLeft: 5 }}
              variant="contained"
              aria-label="delete-licenses"
              onClick={() => setConfirmOpen(true)}
              disabled={selectedLicenses.length === 0}
            >
              Delete
            </Button>
            <ConfirmDialog
              title="Delete licenses"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={() => handleDeleteLicenses(selectedLicenses)}
            >
              Are you sure you want to delete{" "}
              {returnSelectedLicensesNames(selectedLicenses)}?
            </ConfirmDialog>
          </div>
        </Toolbar>
      ) : null}
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell key={column.id} className={classes.tableRow}>
                  <div
                    {...(column.id === "selection"
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    {column.render("Header")}
                    {column.id !== "selection" ? (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? "desc" : "asc"}
                      />
                    ) : null}
                  </div>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={classes.tableRow}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {page.length === 0 ? (
            <TableRow>
              <TableCell align="center" variant="footer">
                No Licenses Match Request
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
        {/* Render Table Pagination based on table length */}
        {data.length > 20 ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  25,
                  50,
                  100,
                  { label: "All", value: data.length },
                ]}
                colSpan={3}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </MaUTable>
    </TableContainer>
  );
}

function removeDuplicates(array, key) {
  let lookup = new Set();
  return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
}

export default function LicensesTable({
  customer,
  hasHeader,
  handleCellClick,
}) {
  const licenses = useSelector(selectAllLicenses);
  const licensesStatus = useSelector((state) => state.licenses.fetchStatus);
  const customersStatus = useSelector((state) => state.customers.fetchStatus);
  const dispatch = useDispatch();
  const [expiredVisible, setExpiredVisible] = useState(false);

  useEffect(() => {
    if (licensesStatus === "idle") {
      dispatch(fetchLicenses());
    }
    if (customersStatus === "idle") {
      dispatch(fetchCustomers());
    }
  }, [licensesStatus, licenses, customersStatus, dispatch]);

  const checkExpiredVisible = (license) => {
    if (expiredVisible) {
      return license;
    } else {
      if (dateDiff(new Date(), parseDate(license.expiry)) >= 0) {
        return license;
      }
    }
  };

  const nullCaseLicenses = [];
  licenses.forEach((license) => {
    const newLicense = JSON.parse(JSON.stringify(license));
    if (newLicense.type === null) {
      newLicense.type = "n/a";
    }
    if (newLicense.partnerName === null) {
      newLicense.partnerName = "n/a";
    }
    nullCaseLicenses.push(newLicense);
  });

  const filterByCustomer = (license) => {
    if (!customer) {
      return license;
    } else {
      return license.customerId === customer.id;
    }
  };

  //filter licenses of archived customers
  const customers = useSelector(selectAllCustomers);
  const activeCustomers = customers.filter(
    (customer) => customer.status !== "archived"
  );
  const filteredLicenses = nullCaseLicenses
    .filter((license) => {
      return activeCustomers.some((customer) => {
        return customer.name === license.customerName;
      });
    })
    //filter licenses that have been 'deleted'
    .filter((license) => license.deleted === false)
    //filter expired licenses
    .filter((license) => checkExpiredVisible(license))
    //filter license data by customer
    .filter((license) => filterByCustomer(license));

  let licenseData = filteredLicenses;
  //remove duplicate licenses in customer context
  if (customer) licenseData = removeDuplicates(filteredLicenses, "pluginkey");

  const COLUMNS = [
    {
      Header: "Plugin Key",
      accessor: "pluginkey",
    },
    {
      Header: () => <div style={{ paddingBottom: 8 }}>Expiry</div>,
      accessor: "expiry",
      disableFilters: true,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (cell) => {
        return <span>{cell.value ? cell.value : "N/A"}</span>;
      },
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Days < expiry",
      accessor: (d) => d.expiry,
      Cell: (cell) => {
        return <span>{dateDiff(new Date(), parseDate(cell.value))}</span>;
      },
      Filter: SliderColumnFilter,
      filter: filterLessThan,
    },
    {
      Header: "Comments",
      accessor: "comment",
    },
  ];

  //in context of CUSTOMER_TAB, hide Customer & Partner columns
  if (!customer) {
    COLUMNS.push(
      {
        Header: "Customer",
        accessor: "customerName",
        Cell: (cell) => {
          return (
            <span
              className="license_table"
              onClick={() => handleCellClick(cell.cell.row.original.customerId)}
            >
              {cell.value}
            </span>
          );
        },
      },
      {
        Header: "Partner",
        accessor: "partnerName",
        Filter: SelectColumnFilter,
        filter: "includes",
      }
    );
  }

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => [...licenseData], [licenseData]);

  const hasExpiredLicenses = () => {
    return licenses.some(
      (license) => dateDiff(new Date(), parseDate(license.expiry)) <= 0
    );
  };

  let content;
  if (
    licensesStatus === "idle" ||
    customersStatus === "idle" ||
    licensesStatus === "loading" ||
    customersStatus === "loading"
  ) {
    content = <CircularProgress />;
  } else if (
    licensesStatus === "succeeded" &&
    customersStatus === "succeeded"
  ) {
    content = (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={expiredVisible}
                onChange={() => setExpiredVisible(!expiredVisible)}
                color="primary"
                disabled={!hasExpiredLicenses()}
              />
            }
            label="Show expired licenses"
          />
        </div>
        <Table
          columns={columns}
          data={data}
          customer={customer}
          hasHeader={hasHeader}
        />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>{content}</div>
  );
}
