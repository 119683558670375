import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SERVER } from '../../app/constants'

const auth = () => {
    let storedUser=JSON.parse(localStorage.getItem("user"));
    if (storedUser) axios.defaults.headers.common['Authorization'] = authenticateUser(storedUser.login, storedUser.password); 
    return storedUser; 
}
const user = auth();

const initialState = user
? { isLoggedIn: true, user, status: 'idle', error: null }
: { isLoggedIn: false, user: null, status: 'idle', error: null };

function authenticateUser(user, password){
    var token = user + ":" + password;
    var hash = btoa(token); 
    return "Basic " + hash;
}

export const login = createAsyncThunk(
    'auth/login',
    async (user) => {
        //axios auth header
        axios.defaults.headers.common['Authorization'] = authenticateUser(user.login, user.password);

        const response = await axios.get(`${SERVER}/rest/user/whoami`)
        if(response.status === 200 && response.data.login===user.login){

            localStorage.setItem('user', JSON.stringify(response.data))
            
        }else {
            localStorage.clear();
        }
        return response.data
    }
)
        
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        logout:{
            reducer(state, action){
                localStorage.removeItem('user');
                state.isLoggedIn = false;
                state.user = null;
             //   document.location.reload();
            }
        }
    },
    extraReducers:{
        [login.pending]: (state, action) => {
            state.status = 'loading'
        },
        [login.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        [login.rejected]: (state, action) => {
            state.status = 'failed';
            state.isLoggedIn = false;
            state.error = action.error.message;
        }
    }
});

export default authSlice.reducer

export const { logout } = authSlice.actions