import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { login } from "./authSlice";

import { Container, Paper, Snackbar } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

import MuiAlert from "@material-ui/lab/Alert";

import LoginForm from "./LoginForm";

export default function LoginPage() {
  const [alertOpen, setAlertOpen] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(login(values));
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Container style={{ marginTop: "50px" }}>
      <div className="og-form">
        <Snackbar
          open={error && alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <MuiAlert onClose={handleAlertClose} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
        <Paper elevation={3} style={{ padding: 30 }}>
          <AccountCircle fontSize="large" color="primary" />
          <LoginForm handleLogin={onSubmit} />
        </Paper>
      </div>
    </Container>
  );
}
