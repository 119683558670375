import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormRender from "@data-driven-forms/react-form-renderer/dist/cjs/form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/dist/cjs/component-types";
import TextField from "@data-driven-forms/mui-component-mapper/dist/cjs/text-field";
import Select from "@data-driven-forms/mui-component-mapper/dist/cjs/select";
import countryList from "react-select-country-list";

import CustomForm from "../../app/components/CustomForm";

import { selectAllPartners, fetchPartners } from "../partners/partnerSlice";
import {
  addNewCustomer,
  updateCustomer,
  fetchCustomers,
} from "./customerSlice";

const componentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
  [componentTypes.SELECT]: Select,
};

//pass customer as prop for initialValues ? called in editMode : null
export default function CustomerForm({ customer, handleCancel, type }) {
  const partners = useSelector(selectAllPartners);
  const partnersStatus = useSelector((state) => state.partners.fetchStatus);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const countryOptions = useMemo(() => countryList().getData(), []);

  const customerSchema = {
    fields: [
      {
        component: "text-field",
        name: "name",
        label: "Name",
        type: "text",
        isRequired: true,
        validate: [
          {
            type: "required",
            message: "*Name required",
          },
        ],
      },
      {
        component: "text-field",
        name: "alias",
        label: "Alias",
        type: "text",
      },
      {
        component: "text-field",
        name: "email",
        label: "Email",
        type: "text",
      },
      {
        component: "select",
        name: "country",
        label: "Country",
        type: "text",
        options: countryOptions,
        isSearchable: true,
      },
    ],
  };

  useEffect(() => {
    if (partnersStatus === "idle") {
      dispatch(fetchPartners());
    }
  }, [partnersStatus, dispatch]);

  const partnerOptions = partners.map((partner) => {
    return {
      label: partner.name,
      //pass the id && name
      value: partner.id,
    };
  });

  //render partnerOptions if currentUser = admin && !already in customerSchema
  if (currentUser) {
    if (
      currentUser.privilege === "super" &&
      customerSchema.fields[2].label !== "Partner"
    ) {
      customerSchema.fields = [
        ...customerSchema.fields.slice(0, 2),
        {
          component: "select",
          label: "Partner",
          name: "partnerId",
          simpleValue: true,
          options: partnerOptions,
          isRequired: true,
        },
        ...customerSchema.fields.slice(2),
      ];
    }
  }

  const onSubmit = async (values) => {
    delete values.partner;
    //add privileges && parse partner values
    values.privilege = "CUSTOMER";
    //check if object includes ID ? updateCustomer : addCustomer
    if (values.id) {
      await dispatch(updateCustomer(values))
        .then((res) => {
          if (res.type === "customers/updateCustomer/fulfilled") {
            dispatch(fetchCustomers());
            handleCancel();
          } else if (res.type === "customers/updateCustomer/failed") {
            console.log("REs: ", res);
          }
        })
        .catch((err) => console.log(err));
    } else {
      await dispatch(addNewCustomer(values))
        .then((res) => {
          if (res.type === "customers/addNewCustomer/fulfilled") {
            handleCancel();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <FormRender
        componentMapper={componentMapper}
        FormTemplate={(props) => (
          <CustomForm type={type} showFormControls={false} {...props} />
        )}
        schema={customerSchema}
        onSubmit={onSubmit}
        initialValues={customer ? customer : null}
        onCancel={handleCancel}
      />
    </div>
  );
}
