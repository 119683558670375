import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { addNewPartner, updatePartner } from "./partnerSlice";

import FormRender from "@data-driven-forms/react-form-renderer/dist/cjs/form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/dist/cjs/component-types";
import TextField from "@data-driven-forms/mui-component-mapper/dist/cjs/text-field";
import Select from "@data-driven-forms/mui-component-mapper/dist/cjs/select";

import CustomForm from "../../app/components/CustomForm";

import countryList from "react-select-country-list";

const componentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
  [componentTypes.SELECT]: Select,
};

export default function PartnerForm({ partner, handleCancel, type }) {
  const dispatch = useDispatch();
  const countryOptions = useMemo(() => countryList().getData(), []);

  const partnerSchema = {
    fields: [
      {
        component: "text-field",
        name: "name",
        label: "Name",
        type: "text",
        isRequired: true,
        validate: [
          {
            type: "required",
            message: "*Enter partner name",
          },
        ],
      },
      {
        component: "text-field",
        name: "email",
        label: "Email",
        type: "text",
      },
      {
        component: "text-field",
        name: "city",
        label: "City",
        type: "text",
      },
      {
        component: "select",
        name: "country",
        label: "Country",
        dataType: "string",
        options: countryOptions,
        isSearchable: true,
      },
    ],
  };

  const onSubmit = async (values) => {
    //check object contains id ? updatePartner : addPartner
    if (values.id) {
      await dispatch(updatePartner(values))
        .then((res) => {
          if (res.type === "partners/updatePartner/fulfilled") {
            handleCancel();
          }
        })
        .catch((err) => console.log(err));
    } else {
      await dispatch(addNewPartner(values))
        .then((res) => {
          if (res.type === "partners/addNewPartner/fulfilled") {
            handleCancel();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <FormRender
        initialValues={partner ? partner : null}
        componentMapper={componentMapper}
        FormTemplate={(props) => (
          <CustomForm type={type} showFormControls={false} {...props} />
        )}
        schema={partnerSchema}
        onSubmit={onSubmit}
        onCancel={handleCancel}
      />
    </div>
  );
}
