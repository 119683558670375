import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

import {
  ADD_PARTNER_CONFIG,
  UPDATE_PARTNER_CONFIG,
  SERVER,
} from "../../app/constants";

const partnerAdapter = createEntityAdapter();

const initialState = partnerAdapter.getInitialState({
  fetchStatus: "idle",
  postStatus: "idle",
  fetchError: null,
  postError: null,
});

export const fetchPartners = createAsyncThunk(
  "partners/fetchPartners",
  async () => {
    const response = await axios.get(`${SERVER}/rest/partner/all`);
    return response.data;
  }
);

export const addNewPartner = createAsyncThunk(
  "partners/addNewPartner",
  async (initialPartner) => {
    //add initialPartner to axios data
    let partnerConfig = ADD_PARTNER_CONFIG;
    partnerConfig.data = initialPartner;
    const response = await axios(partnerConfig);
    return response.data;
  }
);

export const updatePartner = createAsyncThunk(
  "partners/updatePartner",
  async (partner) => {
    let partnerConfig = UPDATE_PARTNER_CONFIG;
    partnerConfig.data = partner;
    const response = await axios(partnerConfig);
    return { id: response.data.id, changes: response.data };
  }
);

const partnerSlice = createSlice({
  name: "partners",
  initialState,
  extraReducers: {
    [fetchPartners.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchPartners.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      partnerAdapter.upsertMany(state, action.payload);
    },
    [fetchPartners.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.fetchError = action.error.message;
    },
    [addNewPartner.fulfilled]: partnerAdapter.addOne,
    [addNewPartner.rejected]: (state, action) => {
      state.postStatus = "failed";
      state.postError = action.error.message;
    },
    [updatePartner.pending]: (state) => {
      state.postStatus = "loading";
    },
    [updatePartner.fulfilled]: (state, action) => {
      state.postStatus = "suceeded";
      partnerAdapter.updateOne(state, action.payload);
    },
    [updatePartner.rejected]: (state, action) => {
      state.postStatus = "failed";
      state.postError = action.error.message;
    },
  },
});

export default partnerSlice.reducer;

export const {
  selectAll: selectAllPartners,
  selectById: selectPartnerById,
  selectIds: selectPartnerIds,
} = partnerAdapter.getSelectors((state) => state.partners);
