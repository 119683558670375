import React, { useState } from 'react'
import { useDispatch } from 'react-redux';

import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core';

import { addNewComment } from './commentsSlice'

const AddCommentDialog = ({ customerId }) => {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('');
    const dispatch = useDispatch();

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleCommentClose = () => {
        setValue('');
        setOpen(false)
    }
    const handleSubmit = async () => {
        if(value !== null && value !== '' && value !== ' '){
            const comment = {
                customer: customerId,
                text: value
            };
            await dispatch(addNewComment(comment))
            .then((res) => {
                if(res.type === 'comments/addNewComment/fulfilled'){
                    handleCommentClose();
                }
            })
            .catch((err) => console.log(err))
        }else{
            window.alert('Please enter a valid comment')
        }
    }

    return (
    <div>
    <Button 
        variant="contained" 
        aria-label="add comment" 
        onClick={handleClickOpen}
    >
        Add Comment
    </Button>
    <Dialog
        open={open}
        onClose={handleCommentClose}
        aria-labelledby="add-comment-dialog"
        fullWidth={true}
    >
        <DialogTitle id="add-comment-textField">Add Comment</DialogTitle>
        <DialogContent>     
            <TextField
                multiline
                rows={4}
                autoFocus={true}
                fullWidth={true}
                variant="outlined"
                value={value}
                onChange={handleChange}
            />
        </DialogContent>
        <DialogActions style={{justifyContent: 'flex-start'}}>
          <Button 
            type="submit" 
            disabled={value.length <= 0}
            color="primary"
            variant="contained"
            onClick={handleSubmit} 
        >
            Save
          </Button>
          <Button 
            color="secondary" 
            variant="contained"
            onClick={handleCommentClose} 
        >
            Cancel
          </Button>
        </DialogActions>
    </Dialog>
    </div>
    )
}

export default AddCommentDialog
