export const parseDate = (str) => {
  var mdy = str.split("-");
  return new Date(mdy[0], mdy[1] - 1, mdy[2]);
};

export const dateDiff = (first, second) => {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  let t = Math.round((second - first) / (1000 * 60 * 60 * 24));
  return t;
};
