import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchComments, updateComment, deleteComment } from "./commentsSlice";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

//formats comment timestamp
const timeParser = (val) => {
  let d = val.split("T")[0].split("-");
  let date = `${d[2]}/${d[1]}/${d[0]}`;
  return date;
};

//capitalizes username
const userParser = (user) => {
  return user.charAt(0).toUpperCase() + user.slice(1);
};

/*
{
    customer: Integer
    date: String
    id: Integer
    partner: Integer
    text: String
    user: String
}
*/

function CommentCard({ comment }) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [commentValue, setCommentValue] = useState(comment.text);
  const dispatch = useDispatch();

  const handleDeleteConfirmation = async () => {
    await dispatch(deleteComment(comment.id))
      .then((res) => {
        if (res.type === "comments/deleteComment/fulfilled") {
          dispatch(fetchComments(comment.customer));
          setOpenDeleteDialog(false);
        } else {
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEditConfirmation = async () => {
    let updatedComment = {
      id: comment.id,
      text: commentValue.trim(),
      customer: comment.customer,
    };
    await dispatch(updateComment(updatedComment))
      .then((res) => {
        if (res.type === "comments/updateComment/fulfilled") {
          dispatch(fetchComments(comment.customer));
        }
      })
      .catch((err) => console.log(err));
    setOpenEditDialog(false);
  };

  const handleChange = (e) => {
    setCommentValue(e.target.value);
  };

  return (
    <>
      <ListItem key={comment.id}>
        <ListItemText
          primary={
            <Typography variant="subtitle2" style={{ color: "#666666" }}>
              <b>{userParser(comment.user)}</b> - {timeParser(comment.date)}
            </Typography>
          }
          secondary={
            <Typography variant="subtitle1" style={{ color: "black" }}>
              {comment.text}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="edit"
            onClick={() => setOpenEditDialog(true)}
          >
            <Edit />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => setOpenDeleteDialog(true)}
          >
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="confirm-comment-deletion"
      >
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this comment?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleDeleteConfirmation}
          >
            Delete
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenDeleteDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="edit-comment-dialog"
      >
        <DialogContent>
          <TextField
            multiline
            rows={4}
            autoFocus={true}
            fullWidth={true}
            variant="outlined"
            value={commentValue}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleEditConfirmation}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenEditDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CommentCard;
