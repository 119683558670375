import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCommentDialog from "./AddCommentDialog";
import CommentCard from "./CommentCard";
import { fetchComments, selectAllComments } from "./commentsSlice";

import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

export default function CommentsList({ customerId }) {
  const comments = useSelector(selectAllComments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchComments(customerId));
  }, [customerId, dispatch]);

  return (
    <div>
      <Card style={{ marginTop: "10px" }}>
        <CardHeader
          action={<AddCommentDialog customerId={customerId} />}
          title="Comments"
        />
        <CardContent>
          <List dense={true}>
            {comments.length === 0 ? (
              <ListItem>
                <ListItemText secondary={"No comments yet"} />
              </ListItem>
            ) : (
              comments.map((comment, i) => (
                <CommentCard comment={comment} key={i} />
              ))
            )}
          </List>
        </CardContent>
      </Card>
    </div>
  );
}
