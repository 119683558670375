import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTable } from 'react-table';
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Button } from '@material-ui/core'

import { fetchCustomers, selectAllCustomers } from '../../features/customer/customerSlice'

function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MaUTable>
    )
  }

  function acceptRow(){
    console.log('accepted')
  }
  function rejectRow(){
    console.log('rejected')
  }

export default function ProtoTable(){
    const [nameSelected, setNameSelected ] = useState(false)
    const customers = useSelector(selectAllCustomers)
    const customersStatus = useSelector(state => state.customers.fetchStatus);

    const dispatch = useDispatch();

    //call customer API 
    useEffect(() => {
        if(customersStatus === 'idle'){
            dispatch(fetchCustomers())
        }
    }, [customersStatus, dispatch]);

    const handleInputChange = (event) => {
        setNameSelected(!nameSelected)
    }

    const COLUMNS = [
        {
            Header: 'Id',
            accessor: 'id'
        },
        {
            Header: 'Name',
            accessor: 'name',
            show: nameSelected
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
          id: 'edit',
          accessor: '[row identifier to be passed to button]',
          Cell: ({value}) => (
              <div>
                <Button onClick={acceptRow({value})}>Accept</Button>
                <Button onClick={rejectRow({value})}>Reject</Button>
              </div>)
        },
    ];

    const customerColumns = useMemo(() => COLUMNS, [COLUMNS]);
    const customerData = useMemo(() => [...customers], [customers]);

    return (
        <div>
        
        <div>
          <label>Name</label>
          <input
            name="firstName"
            type="checkbox"
            checked={nameSelected}
            onChange={handleInputChange}
          />
        </div>
            
        <Table
          data={customerData}
          columns={customerColumns}
        />
        </div>
    )
}
