//displays available plugin names and descriptions
//setting an expiry date + clicking a plugin adds plugin w/ expiry date to customer plugins
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  downloadPluginsById,
  fetchPlugins,
  selectAllPlugins,
} from "./pluginsSlice";

import { getLatestVersion } from "../../utils/versionUtil";

import { selectAllLicenses } from "../licenses/licensesSlice";

import EnhancedTable from "../../app/components/EnhancedTable";
import { GetApp } from "@material-ui/icons";
import {
  Button,
  MenuItem,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { dateDiff, parseDate } from "../../utils/formatUtil";

const generatePluginIdsString = (idsArray) => {
  let idString = "";
  idsArray.forEach((id, index) => {
    if (index < idsArray.length - 1) {
      idString += `${id},`;
    } else {
      idString += `${id}`;
    }
  });

  return idString;
};

const PluginsTable = (props) => {
  const { customerId } = props;
  const plugins = useSelector(selectAllPlugins).filter(
    (p) => p.pluginkey !== "enterprise"
  );
  const pluginsFetchStatus = useSelector((state) => state.plugins.fetchStatus);
  const licenses = useSelector(selectAllLicenses);
  const dispatch = useDispatch();
  const licensesByCustomer = licenses
    .filter((license) => license.customerId === customerId)
    .filter((l) => l.deleted !== true)
    .filter((l) => dateDiff(new Date(), parseDate(l.expiry)) >= 0);

  const [downloadConfig, setDownloadConfig] = useState({});
  //1 = all versions; 2 = v2; 3 = v3
  const [versionsDisplayed, setVersionsDisplayed] = useState("1");

  const pluginsByVersion = () => {
    let filteredPlugins;

    switch (versionsDisplayed) {
      case "1":
        filteredPlugins = plugins;
        break;
      default:
        filteredPlugins = plugins.filter(
          (p) => p.version.charAt(0) === versionsDisplayed
        );
    }

    return filteredPlugins;
  };

  let filteredPlugins = pluginsByVersion();

  const pluginsByCustomer = filteredPlugins.filter((plugin) =>
    licensesByCustomer.find(
      (license) =>
        plugin.pluginkey === license.pluginkey &&
        plugin.version === license.version
    )
  );

  const generateInitialDownloadConfig = () => {
    let pluginHashMap = {};
    pluginsByCustomer.forEach((plugin) => {
      if (versionsDisplayed === "1") {
        pluginHashMap[plugin.pluginkey] = plugin.version;
      } else {
        pluginHashMap[plugin.pluginkey] = getLatestVersion(
          plugins,
          plugin.pluginkey
        );
      }
    });
    return pluginHashMap;
  };

  useEffect(() => {
    if (pluginsFetchStatus === "idle") {
      dispatch(fetchPlugins());
    }

    setDownloadConfig(generateInitialDownloadConfig());
  }, [pluginsFetchStatus, versionsDisplayed, dispatch]);

  const handlePluginVersionChange = (pluginkey, version) => {
    setDownloadConfig(() => ({
      ...downloadConfig,
      [pluginkey]: version,
    }));
  };

  const handlePluginsDownload = () => {
    let pluginIds = [];
    for (const p in downloadConfig) {
      pluginIds.push(
        plugins.find(
          (plugin) =>
            plugin.pluginkey === p && plugin.version === downloadConfig[p]
        ).id
      );
    }

    let pluginIdsString = generatePluginIdsString(pluginIds);

    dispatch(downloadPluginsById(pluginIdsString));
  };

  const COLUMNS = [
    {
      Header: "Product",
      accessor: "product",
    },
    {
      Header: "Plugin Key",
      accessor: "pluginkey",
    },
    {
      Header: "Version",
      accessor: "version",
      Cell: (props) => {
        let tableCellPluginKey = props.cell.row.original.pluginkey;
        let versions = plugins
          .filter((p) => p.pluginkey === tableCellPluginKey)
          .map((v) => v.version);

        return (
          <TextField
            select
            value={downloadConfig[tableCellPluginKey]}
            onChange={(e) =>
              handlePluginVersionChange(tableCellPluginKey, e.target.value)
            }
            variant="outlined"
            disabled={versions.length === 1}
            size="small"
          >
            {versions.map((version, i) => (
              <MenuItem key={`${version}_${i}`} value={version}>
                <Typography variant="body1">{version}</Typography>
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
  ];

  const handleVersionsDisplayedChange = (e) => {
    setVersionsDisplayed(e.target.value);
  };

  //memoize data to avoid re-rendering unless data changes
  const pluginColumns = useMemo(() => COLUMNS, [COLUMNS]);
  const pluginData = useMemo(() => pluginsByCustomer, [plugins]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          startIcon={<GetApp />}
          variant="contained"
          aria-label="download plugins"
          onClick={handlePluginsDownload}
        >
          Download Plugins
        </Button>
        <RadioGroup
          row
          aria-label="selected-version"
          name="version"
          value={versionsDisplayed}
          onChange={handleVersionsDisplayedChange}
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="All"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="Version 2"
          />
          <FormControlLabel
            value="3"
            control={<Radio color="primary" />}
            label="Version 3"
          />
        </RadioGroup>
      </div>
      <EnhancedTable
        columns={pluginColumns}
        data={pluginData}
        tableTitle="Available plugins"
        canFilter={true}
        canCheck={false}
      />
    </>
  );
};

export default PluginsTable;
