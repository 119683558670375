import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import { fade, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    inputRoot: {
      color: 'inherit',
      borderRadius: theme.shape.borderRadius,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 1),
      fontSize: '12px',
      transition: theme.transitions.create('width'),
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 0.87)',
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      '&:focus': {
        border: '2px solid #3f51b5',
      },
      border: '1px solid rgba(0, 0, 0, 0.3)',
      [theme.breakpoints.up('md')]: {
        width: 150,
      }
    },
  }))

const GlobalFilter = ({ globalFilter, setGlobalFilter, searchPlaceholder }) => {
    const classes = useStyles();

    return (
        <div>
        <InputBase
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          }}
          placeholder={searchPlaceholder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
    )
}

export default GlobalFilter
