import React, { useState } from 'react'
import { useSelector } from "react-redux";
import {
    Button,
    CircularProgress,
    IconButton,
    TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

function LoginForm({ handleLogin }) {
    const initialValues = { login: null, password: null };
    const [values, setValues] = useState(initialValues);
    const [showPassword, setShowPassword] = useState(false);
    const loginStatus = useSelector(state => state.auth.status);

    const valid = Boolean(values.login && values.password)

    return (
            <div style={{padding: 10}}>
            <form 
                onSubmit={handleLogin}
                style={{display: 'flex', flexDirection: 'column'}}
            >
            <TextField 
                required
                type="text"
                label="Username"
                name="login"
                onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                />
            <TextField 
                required
                style={{marginTop: 5}}
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                InputProps = {{endAdornment : (
                        <IconButton 
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="action" />}
                        </IconButton>
                )}}
                />
                <Button 
                    style={{marginTop: 20}}
                    disabled={!valid}
                    variant="contained" 
                    color="primary"
                    onClick={() => handleLogin(values)}
                >
                    {loginStatus === 'loading' ? <CircularProgress size={20} style={{color: 'white'}} />  
                    : 'Sign In' }                
                </Button>
            </form>
            </div>
    )
}

export default LoginForm
