import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPartners, selectPartnerById } from "./partnerSlice";
import PartnerForm from "./PartnerForm";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

const profileItems = ["name", "email", "city", "country"];

const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function PartnerProfile({ partnerId, handleClose }) {
  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();

  const partnersStatus = useSelector((state) => state.partners.fetchStatus);
  const partner = useSelector((state) => selectPartnerById(state, partnerId));

  useEffect(() => {
    if (partnersStatus === "idle") {
      dispatch(fetchPartners());
    }
  }, [partnersStatus, dispatch]);

  const handleEditClick = (event) => {
    setEditMode(!editMode);
  };

  let partnerProfile = partner ? (
    <>
      <Typography variant="h6" className="partner_title">
        Partner Profile
      </Typography>
      <Container>
        <Card>
          <CardHeader
            avatar={<Avatar>{partner.name.charAt(0)}</Avatar>}
            action={
              <>
                <Button
                  variant="contained"
                  aria-label="edit"
                  onClick={handleEditClick}
                  color="default"
                  disabled={editMode}
                >
                  Edit
                </Button>
              </>
            }
            title={partner.name}
          />
          <CardContent>
            {editMode ? (
              <PartnerForm
                type="edit"
                partner={partner}
                handleCancel={handleEditClick}
              />
            ) : (
              <List>
                {profileItems.map((item) =>
                  partner[item] ? (
                    item === "partnerName" ? (
                      <ListItem key={item}>
                        <ListItemText primary={`Partner: ${partner[item]}`} />
                      </ListItem>
                    ) : (
                      <ListItem key={item}>
                        <ListItemText
                          primary={`${capitalize(item)}: ${partner[item]}`}
                        />
                      </ListItem>
                    )
                  ) : (
                    <ListItem key={item}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography type="body2" style={{ color: "#AEAEAE" }}>
                            {capitalize(item)}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )
                )}
              </List>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  ) : (
    <p>Loading...</p>
  );

  return <div>{partnerProfile}</div>;
}
