import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { fetchCustomers } from "../customer/customerSlice";
import { fetchPlugins } from "../plugins/pluginsSlice";
import { addNewLicense, fetchLicenses } from "./licensesSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
    marginLeft: -5,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function dateFormatter(date) {
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let day;
  let month;
  if (d < 10) {
    day = `0${d}`;
  } else {
    day = d;
  }
  if (m < 10) {
    month = `0${m}`;
  } else {
    month = m;
  }
  let y = date.getFullYear();
  return `${y}-${month}-${day}`;
}

const initialLicense = {
  customerId: null,
  expiry: null,
  type: null,
  plugins: [],
  comment: null,
  deleted: null,
};

export default function EditLicenseDialog({
  customer,
  disabled,
  selectedLicenses,
}) {
  const classes = useStyles();
  const dt = new Date();
  dt.setMonth(dt.getMonth() + 1);
  const defaultDate = dateFormatter(dt);
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [license, setLicense] = useState(initialLicense);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  const customersStatus = useSelector((state) => state.customers.fetchStatus);
  const pluginsStatus = useSelector((state) => state.plugins.fetchStatus);

  useEffect(() => {
    if (customersStatus === "idle") {
      dispatch(fetchCustomers());
    }
    if (pluginsStatus === "idle") {
      dispatch(fetchPlugins());
    }
  }, [customersStatus, pluginsStatus, dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
    if (customer && selectedLicenses) {
      setLicense({
        ...license,
        customerId: customer.id,
        type: selectedLicenses.length === 1 ? selectedLicenses[0].type : null,
        expiry: selectedDate,
        plugins: [...selectedLicenses],
        comment: "",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLicense(initialLicense);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setLicense({ ...license, expiry: date });
  };

  const setComment = (license, plugin, selectedLicenses) => {
    if (license.comment !== "") {
      return license.comment;
    } else {
      let pluginRef = license.plugins.find(
        (p) => p.pluginkey === plugin.pluginkey
      );

      if (pluginRef && pluginRef.comment) return pluginRef.comment;
    }

    return "";
  };

  const handleSave = async () => {
    setSaving(true);
    //licenses get added as an Array of Objects
    let licenseArr = [];
    license.plugins.forEach((plugin) => {
      let newLicense = {
        customerId: license.customerId,
        expiry: license.expiry,
        pluginkey: plugin.pluginkey,
        version: plugin.version,
        zipfile: plugin.zipfile,
        type: license.type,
        comment: setComment(license, plugin, selectedLicenses),
      };

      licenseArr.push(newLicense);
    });
    await dispatch(addNewLicense(licenseArr))
      .then((res) => {
        if (res.type === "licenses/addNewLicense/fulfilled") {
          setSaving(false);
          dispatch(fetchLicenses());
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setLicense({ ...license, [name]: value });
    };

  const canSave = Boolean(license.type && license.expiry);

  return (
    <div>
      <Button
        disabled={disabled}
        aria-label="add license"
        onClick={handleClickOpen}
        variant="contained"
        style={{ marginLeft: 5 }}
      >
        Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-license-form"
        fullWidth={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "5px",
          }}
        >
          <DialogTitle id="add-license-form">
            {selectedLicenses.length === 1 ? "Edit License" : "Edit Licenses"}
          </DialogTitle>
        </div>
        <DialogContent className="license-dialog-content">
          <FormControl style={{ display: "block" }}>
            {selectedLicenses.map((plugin, i) => (
              <Chip key={i} label={plugin.pluginkey} style={{ margin: 1 }} />
            ))}
          </FormControl>
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              defaultValue={license.type ? license.type : ""}
              margin="dense"
              label="Type"
              type="select"
              fullWidth
              onChange={handleChange("type")}
            >
              <MenuItem value="demo">Demo</MenuItem>
              <MenuItem value="eval">Eval</MenuItem>
              <MenuItem value="perm">Perm</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <TextField
              id="comment"
              label="Comment"
              type="text"
              multiline
              rows={4}
              fullWidth
              onChange={handleChange("comment")}
            />
          </FormControl>
          <form className={classes.container}>
            <TextField
              id="date"
              label="Expiry Date"
              type="date"
              defaultValue={selectedDate}
              fullWidth
              onChange={(e) => {
                handleDateChange(e.target.value);
              }}
            />
          </form>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          {saving ? (
            <CircularProgress />
          ) : (
            <Tooltip
              title={
                canSave ? "" : "Make sure licenses have a 'type' and 'expiry'"
              }
            >
              <span>
                {/*span here so tooltip doesn't throw warning -> disabled buttons does not fire events */}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  disabled={!canSave}
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          )}
          <Button color="secondary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
