export const getLatestVersion = (plugins, pluginkey) => {
  let versionsArr = plugins
    .filter((p) => p.pluginkey === pluginkey)
    .map((p) => p.version);

  let latestVersion = versionsArr[0];

  for (var i = 1; i < versionsArr.length; i++) {
    if (nextVersionHigher(latestVersion, versionsArr[i]))
      latestVersion = versionsArr[i];
  }
  return latestVersion;
};

export const getLatestPlugins = (plugins) => {
  let latest = [];

  plugins.forEach((p) => {
    if (!latest.find((item) => item.pluginkey === p.pluginkey)) {
      let plugin = plugins.find(
        (plugin) =>
          plugin.version === getLatestVersion(plugins, p.pluginkey) &&
          plugin.pluginkey === p.pluginkey
      );
      if (plugin) latest.push(plugin);
    }
  });

  return latest;
};

const nextVersionHigher = (v1, v2) => {
  let v1Arr = v1.split(".");
  let v2Arr = v2.split(".");
  let positions = Math.min(v1Arr.length, v2Arr.length);

  for (var i = 0; i < positions; i++) {
    if (nextNumHigher(v1Arr[i], v2Arr[i])) return true;
  }

  return v1.length > v2.length;
};

const nextNumHigher = (rel1, rel2) => {
  let num1 = parseInt(rel1);
  let num2 = parseInt(rel2);
  if (num1 < num2) return true;
  if (num2 < num1) return false;
  return rel1.length < rel2.length;
};
