import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { SERVER } from "../../app/constants";

import axios from "axios";

const licensesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

const initialState = licensesAdapter.getInitialState({
  //returns an empty {ids: [], entities: {}} normalized state object
  fetchStatus: "idle",
  fetchError: null,
  postStatus: "idle",
  postError: null,
  updateStatus: "idle",
  updateError: null,
});

export const fetchLicenses = createAsyncThunk(
  "licenses/fetchLicenses",
  async () => {
    const response = await axios(`${SERVER}/rest/license/all`);
    return response.data;
  }
);

export const addNewLicense = createAsyncThunk(
  "licenses/addNewLicense",
  async (newLicense) => {
    let ADD_LICENSE_CONFIG = {
      method: "POST",
      url: `${SERVER}/rest/license?customer=${newLicense[0].customerId}`,
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
      },
    };
    ADD_LICENSE_CONFIG.data = newLicense;
    const response = await axios(ADD_LICENSE_CONFIG);
    return response.data;
  }
);

export const downloadLicenses = createAsyncThunk(
  "licenses/downloadLiceneses",
  async (customerId) => {
    await axios
      .request({
        url: `${SERVER}/rest/download/license?customer=${customerId}`,
        method: "GET",
        responseType: "blob",
      })
      .then((res) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "license.json"); //file type??
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
);

const licensesSlice = createSlice({
  name: "licenses",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLicenses.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchLicenses.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      licensesAdapter.setAll(state, action.payload);
    },
    [fetchLicenses.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.fetchError = action.error.message;
    },
    [addNewLicense.fulfilled]: (state) => {
      state.postStatus = "succeeded";
    },
    [addNewLicense.rejected]: (state, action) => {
      state.postStatus = "failed";
      state.postError = action.error.message;
    },
  },
});

export default licensesSlice.reducer;

export const {
  selectAll: selectAllLicenses,
  selectById: selectLicenseById,
  selectIds: selectLicenseIds,
} = licensesAdapter.getSelectors((state) => state.licenses);
