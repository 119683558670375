import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import BasicTable from "../../app/components/BasicTable";
import AddPartnerDialog from "./AddPartnerDialog";

import { fetchPartners, selectAllPartners } from "./partnerSlice";

export default function PartnerTable({ handlePartnerSelect }) {
  const partners = useSelector(selectAllPartners);
  const partnersFetchStatus = useSelector(
    (state) => state.partners.fetchStatus
  );
  const fetchError = useSelector((state) => state.partners.fetchError);
  const dispatch = useDispatch();

  //call customer API
  useEffect(() => {
    if (partnersFetchStatus === "idle") {
      dispatch(fetchPartners());
    }
  }, [partnersFetchStatus, dispatch]);

  //react-table column data
  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Country",
      accessor: "country",
    },
  ];

  //memoize data to avoid re-rendering unless data changes
  const partnerColumns = useMemo(() => COLUMNS, [COLUMNS]);
  const partnerData = useMemo(() => partners, [partners]);

  if (partnerData[0] === "<" || partnerData.length === 0)
    return (
      <>
        <p>Add some partners to get started</p>
        <AddPartnerDialog />
      </>
    );
  //create table
  let partnersTable = (
    <BasicTable
      columns={partnerColumns}
      data={partnerData}
      tableTitle="Partners"
      hasHeader={true}
      canAdd={true}
      headerComponent={<AddPartnerDialog />}
      handleRowClick={handlePartnerSelect}
    />
  );

  //set content based on customer API status
  let content;
  if (partnersFetchStatus === "loading") {
    content = <h4>Loading Partners...</h4>;
  } else if (partnersFetchStatus === "succeeded") {
    content = partnersTable;
  } else if (partnersFetchStatus === "failed") {
    content = <h4>{fetchError}</h4>;
  }

  return (
    <div>
      {/* mssge if list ? empty : errorMssge || loadingMssge */}
      {!fetchError && partners.length === 0 ? (
        <p>Add some partners to get started</p>
      ) : (
        content
      )}
    </div>
  );
}
