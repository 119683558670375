import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCustomers,
  deleteCustomer,
  selectCustomerById,
} from "./customerSlice";
import { fetchLicenses } from "../licenses/licensesSlice";

import CustomerForm from "./CustomerForm";
import CommentsList from "../comments/CommentsList";
import ConfirmDialog from "../../app/components/ConfirmDialog";
import LicensesTable from "../licenses/LicensesTable";
import PluginsTable from "../plugins/PluginsTable";
import AuditsTable from "../audits/AuditsTable";

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const profileItems = ["name", "alias", "partnerName", "email", "country"];

const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function CustomerProfile({ customerId, handleClose }) {
  const [value, setValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const dispatch = useDispatch();

  const customersStatus = useSelector((state) => state.customers.fetchStatus);
  const licensesStatus = useSelector((state) => state.licenses.fetchStatus);
  const customerUpdateStatus = useSelector(
    (state) => state.customers.postStatus
  );
  const partnerUpdateStatus = useSelector((state) => state.partners.postStatus);

  const customer = useSelector((state) =>
    selectCustomerById(state, customerId)
  );

  useEffect(() => {
    if (customersStatus === "idle") {
      dispatch(fetchCustomers());
    }
    if (licensesStatus === "idle") {
      dispatch(fetchLicenses());
    }
  }, [customersStatus, licensesStatus, dispatch]);

  useEffect(() => {
    if (customerUpdateStatus === "loading") {
      dispatch(fetchLicenses());
    }
  }, [customerUpdateStatus, dispatch]);

  useEffect(() => {
    if (partnerUpdateStatus === "loading") {
      dispatch(fetchCustomers());
    }
  }, [partnerUpdateStatus, dispatch]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditClick = (event) => {
    setEditMode(!editMode);
  };

  const handleDeleteCustomer = async (customer) => {
    const deletedCustomer = JSON.parse(JSON.stringify(customer));
    deletedCustomer.status = "archived";
    await dispatch(deleteCustomer(deletedCustomer))
      .then((res) => {
        if (res.type === "customers/updateCustomer/fulfilled") {
          setConfirmOpen(false);
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  let customerProfile = customer ? (
    <>
      <AppBar position="relative" style={{ background: "#6573c3" }}>
        <Typography variant="h6" component="div" className="customer_title">
          Customer Profile - {customer.name}
        </Typography>
        <Tabs
          centered
          value={value}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "white" } }}
        >
          <Tab label="Profile" value={0} />
          <Tab label="Licenses" value={1} />
          <Tab label="Audit Trail" value={2} />
          <Tab label="Downloads" value={3} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Container>
          <Card>
            <CardHeader
              avatar={<Avatar>{customer.name.charAt(0)}</Avatar>}
              action={
                <>
                  <Button
                    variant="contained"
                    aria-label="edit"
                    onClick={handleEditClick}
                    color="default"
                    disabled={editMode}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    variant="contained"
                    aria-label="delete-customer"
                    onClick={() => setConfirmOpen(true)}
                  >
                    Delete
                  </Button>
                  <ConfirmDialog
                    title={`Delete ${customer.name}?`}
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={() => handleDeleteCustomer(customer)}
                  >
                    Are you sure you want to delete {customer.name}?
                  </ConfirmDialog>
                </>
              }
              title={customer.name}
            />
            <CardContent>
              {editMode ? (
                <CustomerForm
                  type="edit"
                  customer={customer}
                  handleCancel={handleEditClick}
                />
              ) : (
                <List>
                  {profileItems.map((item) =>
                    customer[item] ? (
                      item === "partnerName" ? (
                        <ListItem key={item}>
                          <ListItemText
                            primary={`Partner: ${customer[item]}`}
                          />
                        </ListItem>
                      ) : (
                        <ListItem key={item}>
                          <ListItemText
                            primary={`${capitalize(item)}: ${customer[item]}`}
                          />
                        </ListItem>
                      )
                    ) : (
                      <ListItem key={item}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: "#AEAEAE" }}
                            >
                              {capitalize(item)}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}
            </CardContent>
          </Card>
          <CommentsList customerId={customerId} />
        </Container>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LicensesTable customer={customer} hasHeader={true} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AuditsTable customerId={customerId} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PluginsTable customerId={customerId} />
      </TabPanel>
    </>
  ) : (
    <p>Loading...</p>
  );

  return <div>{customerProfile}</div>;
}
