import React from "react";

import { useTable, useGlobalFilter, useSortBy } from "react-table";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { Toolbar } from "@material-ui/core";

import GlobalFilter from "../../app/components/GlobalFilter";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    padding: "10px",
    margin: 0,
  },
}));

const filterCaseInsensitive = (a, b) => {
  let r1 = a.toLowerCase();
  let r2 = b.toLowerCase();
  if (r1 < r2) {
    return -1;
  }
  if (r1 > r2) {
    return 1;
  }
  return 0;
};

const BasicTable = ({
  columns,
  data,
  headerComponent,
  handleRowClick,
  canAdd,
  tableTitle,
}) => {
  const {
    state,
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      sortTypes: {
        alphanumeric: (row1, row2, columnId) => {
          return filterCaseInsensitive(
            row1.values[columnId],
            row2.values[columnId]
          );
        },
      },
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  const classes = useStyles();
  const count = rows.length;

  return (
    <>
      <Toolbar variant="dense" style={{ justifyContent: "space-between" }}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          searchPlaceholder={`Search ${count} records...`}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {canAdd ? headerComponent : null}
        </div>
      </Toolbar>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classes.tableRow}
                >
                  {column.render("Header")}
                  <TableSortLabel
                    active={column.isSorted}
                    direction={column.isSortedDesc ? "desc" : "asc"}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={
                  handleRowClick ? () => handleRowClick(row.original) : null
                }
                // onClick={handleRowClick ? () => handleRowClick(row.original) : () => console.log('UNDEFINED', row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={classes.tableRow}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
    </>
  );
};

export default BasicTable;
