import React, { useEffect, useState } from 'react'
import { 
    AppBar,
    Container,
    Dialog,
    IconButton,
    Toolbar,
    Slide
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import PartnerTable from '../features/partners/PartnerTable'
import PartnerProfile from '../features/partners/PartnerProfile'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PartnersPage(){
    const [dialogOpen, setDialogOpen] = useState(false);
    const [activePartner, setActivePartner] = useState(null);

    useEffect(() => {
        if(activePartner){
            setDialogOpen(true)
        }else{
            return
        }
    }, [activePartner]);

    const handleTableClick = (partner) => {
        setActivePartner(partner.id)
    }
    
    const handleDialogClose = () => {
        setDialogOpen(false)
        setActivePartner(null)
    }


    return (
        <div>
            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
            >
                <AppBar position="relative" style={{ backgroundColor: '#2c387e' }}>
                    <Toolbar>
                        <IconButton onClick={handleDialogClose} style={{ color: 'white' }}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                    <PartnerProfile partnerId={activePartner} />
            </Dialog>
            <Container>
                <PartnerTable handlePartnerSelect={handleTableClick} />
            </Container>
        </div>
    )
}
 
export default PartnersPage
