import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import axios from "axios";

import {
  ADD_CUSTOMER_CONFIG,
  UPDATE_CUSTOMER_CONFIG,
  GET_CUSTOMERS_CONFIG,
} from "../../app/constants";

const customerAdapter = createEntityAdapter();

const initialState = customerAdapter.getInitialState({
  //returns an empty {ids: [], entities: {}} normalized state object
  fetchStatus: "idle",
  fetchError: null,
  postStatus: "idle",
  postError: null,
});

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async () => {
    let custConfig = GET_CUSTOMERS_CONFIG;
    const response = await axios(custConfig);
    return response.data;
  }
);

export const addNewCustomer = createAsyncThunk(
  "customers/addNewCustomer",
  //payload creator receives initialCustomer -> prepare() in customerAdded reducer
  async (initialCustomer) => {
    let customerConfig = ADD_CUSTOMER_CONFIG;
    customerConfig.data = initialCustomer;
    const response = await axios(customerConfig);
    return response.data;
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/updateCustomer",
  async (customer) => {
    let customerConfig = UPDATE_CUSTOMER_CONFIG;
    customerConfig.data = customer;
    const response = await axios(customerConfig);
    return { id: response.data.id, changes: response.data };
  }
);

export const deleteCustomer = createAsyncThunk(
  "customers/updateCustomer",
  async (customer) => {
    let customerConfig = UPDATE_CUSTOMER_CONFIG;
    customerConfig.data = customer;
    const response = await axios(customerConfig);
    return { id: response.data.id, changes: response.data };
  }
);

const customerSlice = createSlice({
  name: "customers",
  initialState,
  extraReducers: {
    [fetchCustomers.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchCustomers.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      customerAdapter.upsertMany(state, action.payload);
    },
    [fetchCustomers.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.fetchError = action.error.message;
    },
    [addNewCustomer.fulfilled]: customerAdapter.addOne,
    [addNewCustomer.rejected]: (state, action) => {
      state.postStatus = "failed";
      state.postError = action.error.message;
    },
    [updateCustomer.pending]: (state) => {
      state.postStatus = "loading";
    },
    [updateCustomer.fulfilled]: (state, action) => {
      state.postStatus = "suceeded";
      customerAdapter.updateOne(state, action.payload);
    },
    [updateCustomer.rejected]: (state, action) => {
      state.postStatus = "failed";
      state.postError = action.error.message;
    },
  },
});

export default customerSlice.reducer;

export const {
  selectAll: selectAllCustomers,
  selectById: selectCustomerById,
  selectIds: selectCustomerIds,
} = customerAdapter.getSelectors((state) => state.customers);
