import React, { useState } from 'react'
import { useDispatch } from 'react-redux';

import { 
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
} from '@material-ui/core';

import { addNewPartner, fetchPartners, updatePartner } from './partnerSlice'
import PartnerForm from './PartnerForm'

const AddPartnerDialog = () => {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    
    const handlePartnerSubmit = values => {
        //check object contains id ? updatePartner : addPartner
        if(values.id){
            dispatch(updatePartner(values))
        }else{
            dispatch(addNewPartner(values))
        }
        dispatch(fetchPartners());
    }  

    return (
    <div>
    <Button variant="contained" aria-label="add partner" onClick={handleClickOpen}>
        Add Partner
    </Button>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-partner-form"
        fullWidth={true}
    >
        <DialogTitle id="add-partner-form">Add Partner</DialogTitle>
        <DialogContent className="partner-dialog-content">     
        <FormControl fullWidth={true}>
            <PartnerForm 
                handlePartnerSubmit={handlePartnerSubmit} 
                handleCancel={handleClose}
            />
        </FormControl>
        </DialogContent> 
    </Dialog>
    </div>
    )
}

export default AddPartnerDialog
