import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import axios from "axios";

import {
  ADD_COMMENT_CONFIG,
  DELETE_COMMENT_CONFIG,
  SERVER,
} from "../../app/constants";

const commentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

const initialState = commentsAdapter.getInitialState({
  fetchStatus: "idle",
  fetchError: null,
  postStatus: "idle",
  postError: null,
});

export const fetchComments = createAsyncThunk(
  "comments/fetchComments",
  async (customerId) => {
    const response = await axios.get(
      `${SERVER}/rest/comment?customer=${customerId}`
    );
    return response.data;
  }
);

export const addNewComment = createAsyncThunk(
  "comments/addNewComment",
  async (initialComment) => {
    let commentConfig = ADD_COMMENT_CONFIG;
    commentConfig.data = initialComment;
    const response = await axios(commentConfig);
    return response.data;
  }
);

export const updateComment = createAsyncThunk(
  "comments/updateComment",
  async (comment) => {
    let commentConfig = ADD_COMMENT_CONFIG;
    commentConfig.url = `${SERVER}/rest/comment?customer=${comment.customer}`;
    commentConfig.data = comment;
    const response = await axios(commentConfig);
    return response.data;
  }
);

export const deleteComment = createAsyncThunk(
  "comments/deleteComment",
  async (commentId) => {
    let commentConfig = DELETE_COMMENT_CONFIG;
    commentConfig.url = `${SERVER}/rest/comment?comment=${commentId}`;
    const response = await axios(commentConfig);
    return response.data;
  }
);

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  extraReducers: {
    [addNewComment.pending]: (state) => {
      state.postStatus = "loading";
    },
    [addNewComment.fulfilled]: commentsAdapter.setAll,
    [addNewComment.rejected]: (state, action) => {
      state.postStatus = "failed";
      state.postError = action.error.message;
    },
    [fetchComments.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchComments.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      commentsAdapter.setAll(state, action.payload);
    },
    [fetchComments.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.fetchError = action.error.message;
    },
  },
});

export default commentsSlice.reducer;

export const {
  selectAll: selectAllComments,
  selectById: selectCommentsById,
  selectIds: selectCommentsIds,
} = commentsAdapter.getSelectors((state) => state.comments);
