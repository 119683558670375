import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { SERVER } from "../../app/constants";

import axios from "axios";

const pluginsAdapter = createEntityAdapter();

const initialState = pluginsAdapter.getInitialState({
  fetchStatus: "idle",
  fetchError: null,
});

export const fetchPlugins = createAsyncThunk(
  "plugins/fetchPlugins",
  async () => {
    const response = await axios(`${SERVER}/rest/plugin/all`);
    return response.data;
  }
);

export const downloadPlugins = createAsyncThunk(
  "plugins/downloadPlugins",
  async (customerId) => {
    return axios
      .request({
        url: `${SERVER}/rest/download/zip?customer=${customerId}`,
        method: "GET",
        responseType: "blob",
      })
      .then((res) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "plugins.zip"); //file type??
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
);

export const downloadPluginsById = createAsyncThunk(
  "plugins/downloadPluginsById",
  async (pluginIds) => {
    return axios
      .request({
        url: `${SERVER}/rest/download/plugins?id=${pluginIds}`,
        method: "GET",
        responseType: "blob",
      })
      .then((res) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "plugins.zip"); //file type??
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
);

const pluginsSlice = createSlice({
  name: "plugins",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPlugins.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchPlugins.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      pluginsAdapter.upsertMany(state, action.payload);
    },
    [fetchPlugins.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.fetchError = action.error.message;
    },
  },
});

export default pluginsSlice.reducer;

export const {
  selectAll: selectAllPlugins,
  selectById: selectPluginById,
  selectIds: selectPluginIds,
} = pluginsAdapter.getSelectors((state) => state.plugins);
