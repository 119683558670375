import React, { useState } from 'react'

import { 
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
} from '@material-ui/core';

import CustomerForm from './CustomerForm'

const AddCustomerDialog = () => {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
    <div>
    <Button variant="contained" aria-label="add customer" onClick={handleClickOpen}>
        Add Customer
    </Button>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-customer-form"
        fullWidth={true}
    >
        <DialogTitle id="add-customer-form">Add Customer</DialogTitle>
        <DialogContent className="customer-dialog-content">     
        <FormControl fullWidth={true} >
            <CustomerForm type="add" handleCancel={handleClose} />
        </FormControl>
        </DialogContent>
    </Dialog>
    </div>
    )
}

export default AddCustomerDialog
