import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddCustomerDialog from "./AddCustomerDialog";
import { fetchCustomers, selectAllCustomers } from "./customerSlice";
import EnhancedTable from "../../app/components/EnhancedTable";

//TODO: export filters for EnhancedTable in more maintainable way
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <FormControl>
      <Select
        defaultValue={""}
        value={filterValue ? filterValue : ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <MenuItem value="">All</MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option ? option : ""}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function CustomerTable({ handleCustomerSelect }) {
  const customers = useSelector(selectAllCustomers);
  const unarchivedCustomers = customers.filter(
    (customer) => customer.status !== "archived"
  );
  const customersStatus = useSelector((state) => state.customers.fetchStatus);

  const error = useSelector((state) => state.customers.fetchError);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  //call customer API
  useEffect(() => {
    if (customersStatus === "idle") {
      dispatch(fetchCustomers());
    }
  }, [customersStatus, dispatch]);

  //react-table column data
  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Alias",
      accessor: "alias",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Country",
      accessor: "country",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
  ];

  //hide partner column if partner is logged in
  if (currentUser) {
    if (currentUser.privilege !== "partner") {
      COLUMNS.push({
        id: "partnerName", //necessary b/c accessor !String
        Header: "Partner",
        accessor: (customerData) => customerData.partnerName,
        Filter: SelectColumnFilter,
        filter: "includes",
      });
    }
  }

  const nullCaseCustomers = [];
  unarchivedCustomers.forEach((customer) => {
    const newCustomer = JSON.parse(JSON.stringify(customer));
    if (newCustomer.partnerName === null) {
      newCustomer.partnerName = "n/a";
    }
    if (newCustomer.country === null) {
      newCustomer.country = "n/a";
    }
    if (newCustomer.alias === null) {
      newCustomer.alias = "n/a";
    }
    nullCaseCustomers.push(newCustomer);
  });

  //memoize data to avoid re-rendering unless data changes
  const customerColumns = useMemo(() => COLUMNS, [COLUMNS]);
  const customerData = useMemo(
    () => [...nullCaseCustomers],
    [nullCaseCustomers]
  );

  //set content based on customer API status

  let content;
  if (customersStatus === "loading") {
    content = <CircularProgress />;
  } else if (customersStatus === "succeeded") {
    if (customerData[0] === "<" || customerData.length === 0)
      return (
        <>
          <p>Add some customers to get started</p>
          <AddCustomerDialog />
        </>
      );
    content = (
      <EnhancedTable
        columns={customerColumns}
        data={customerData}
        handleRowClick={handleCustomerSelect}
        addComponent={<AddCustomerDialog />}
        sortPref={{
          id: "name",
          desc: false,
        }}
      />
    );
  } else if (customersStatus === "failed") {
    content = <h4>{error}</h4>;
  }

  return <div>{content}</div>;
}
