import { configureStore } from '@reduxjs/toolkit'

import authReducer from '../features/auth/authSlice'
import auditsReducer from '../features/audits/auditsSlice'
import commentsReducer from '../features/comments/commentsSlice'
import customerReducer from '../features/customer/customerSlice'
import partnerReducer from '../features/partners/partnerSlice'
import licensesReducer from '../features/licenses/licensesSlice'
import pluginsReducer from '../features/plugins/pluginsSlice'

export default configureStore({
  reducer: {
    auth: authReducer,
    audits: auditsReducer,
    comments: commentsReducer,
    customers: customerReducer,
    partners: partnerReducer,
    licenses: licensesReducer,
    plugins: pluginsReducer
  }
})
