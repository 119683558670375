import React, { useState } from "react";
import { useSelector } from "react-redux";

import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";

import CustomersPage from "./CustomersPage";
import PartnersPage from "./PartnersPage";
import LicensesPage from "./LicensesPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function HomePage() {
  const [tabValue, setTabValue] = useState(0);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      {isLoggedIn ? (
        <>
          <AppBar position="relative" style={{ background: "#6573c3" }}>
            <Tabs
              centered
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#fafafa" } }}
            >
              <Tab label="Partners" value={0} />
              <Tab label="Customers" value={1} />
              <Tab label="Licenses" value={2} />
            </Tabs>
          </AppBar>

          <TabPanel value={tabValue} index={0}>
            <PartnersPage />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <CustomersPage />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <LicensesPage />
          </TabPanel>
        </>
      ) : null}
    </div>
  );
}

export default HomePage;
