import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useHistory, useLocation, Link } from "react-router-dom";
import {
  AppBar,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { AccountCircle, ExitToApp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { PrivateRoute } from "./features/auth/PrivateRoute";

import HomePage from "./layout/HomePage";
import Page404 from "./app/components/Page404";
import LoginPage from "./features/auth/LoginPage";
import TestPage from "./layout/TestPage";

import { logout } from "./features/auth/authSlice";

import "./App.css";

const useStyles = makeStyles((theme) => ({
  spaced: {
    justifyContent: "space-between",
  },
  button: {
    color: "white",
    textTransform: "none",
    "&.active": {
      borderBottom: "2px solid #fafafa",
      color: "#fafafa",
      fontWeight: 600,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function App() {
  // const { isLoggedIn } = useSelector((state) => state.auth);
  let { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  //anchor for accountMenu dropdown
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/signIn");
    }
  }, [isLoggedIn, history]);

  const handleLogout = () => {
    //delete AnchorEl ref or account menu stays open after logout
    setAnchorEl(null);
    dispatch(logout());
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar className={classes.spaced}>
          <img src="images/1gateway-logo-white.png" width={150} />
          {isLoggedIn ? (
            <div>
              <IconButton onClick={handleMenuOpen}>
                <AccountCircle style={{ color: "white" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem onClick={handleLogout}>
                  {/*ListItem default renders li === error li !descendent of li */}
                  <ListItem component="div">
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" />
                  </ListItem>
                </MenuItem>
              </Menu>
            </div>
          ) : location.pathname === "/signIn" ? null : (
            <Button color="inherit">
              <Link to={"/signIn"}>Login</Link>
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/signIn" component={LoginPage} />
        <PrivateRoute
          exact
          path="/test"
          privilege={["super"]}
          component={TestPage}
        />

        <Route path="/" component={Page404} />
      </Switch>
    </div>
  );
}

export default App;
