export const SERVER = "";
//'http://portal-copy.rancher.acentix.local'
// export const SERVER = "http://localhost:8080";

export const GET_CUSTOMERS_CONFIG = {
  method: "GET",
  url: `${SERVER}/rest/customer/all`,
};

//delete customer feat
//add new api features
//http://portal-copy.rancher.acentix.local/

export const ADD_COMMENT_CONFIG = {
  method: "POST",
  url: `${SERVER}/rest/comment`,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
  },
};

export const UPDATE_COMMENT_CONFIG = {
  method: "POST",
  url: `${SERVER}/rest/comment`,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
  },
};

export const DELETE_COMMENT_CONFIG = {
  method: "DELETE",
  url: `${SERVER}/rest/comment?comment=`,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
  },
};

export const ADD_CUSTOMER_CONFIG = {
  method: "POST",
  url: `${SERVER}/rest/customer`,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
  },
};

export const ADD_PARTNER_CONFIG = {
  method: "POST",
  url: `${SERVER}/rest/partner`,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
};

export const UPDATE_PARTNER_CONFIG = {
  method: "POST",
  url: `${SERVER}/rest/partner`,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
  },
};

export const UPDATE_CUSTOMER_CONFIG = {
  method: "POST",
  url: `${SERVER}/rest/customer`,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
  },
};

export const AUTH_CONFIG = {
  method: "GET",
  url: `${SERVER}/rest/user/whoami`,
  headers: {
    accept: "*/*",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
  },
};

export const GET_LICENSES_CONFIG = {
  method: "GET",
  //url: `${SERVER}/rest/license?customer=${CUSTOMER_ID}`
};
